import { XMarkIcon } from "@heroicons/react/24/outline";
import { useState } from "react";

type BannerProps = {
  text: string;
  textMobile?: string;
};
const TopBanner = ({ text, textMobile = text }: BannerProps) => {
  const [shown, setShown] = useState(true);

  return (
    <>
      {shown && (
        <div className="relative bg-primary-600">
          <div className="mx-auto max-w-7xl px-3 py-3 sm:px-6 lg:px-8">
            <div className="pr-16 sm:px-16 sm:text-center">
              <p className="font-medium text-white">
                <span className="md:hidden">{textMobile}</span>
                <span className="hidden md:inline">{text}</span>
              </p>
            </div>
            <div className="absolute inset-y-0 right-0 flex items-start pr-1 pt-1 sm:items-start sm:pr-2 sm:pt-1">
              <button
                type="button"
                onClick={() => setShown(false)}
                className="flex rounded-sm p-2 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-white"
              >
                <span className="sr-only">Dismiss</span>
                <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default TopBanner;
