import dynamic from "next/dynamic";
import { memo } from "react";

type Props = { countryCode: string } & SvgProps;

type HTMLSVGElement = HTMLElement & SVGElement;

interface ElementAttributes<T>
  extends React.HTMLAttributes<T>,
    React.SVGAttributes<T> {}

type SvgProps = ElementAttributes<HTMLSVGElement>;

export const CountryFlag = memo(function CountryFlagComponent({
  countryCode,
  ...rest
}: Props) {
  const FlagSvg = dynamic<ElementAttributes<HTMLSVGElement>>(
    async () => {
      return await import(
        `react-world-flags/src/svgs/${countryCode.toLowerCase()}.svg`
      );
    },
    {
      ssr: false,
      loading: () => <div className={rest.className} />,
    }
  );

  return <FlagSvg {...rest} />;
});
